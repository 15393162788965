// esg-frontend/src/components/LoginForm.js

import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Paper, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ setAuth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuth(true);
      navigate("/analiseesg"); // Usuário já autenticado
    }
  }, [setAuth, navigate]);

  const validateInputs = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Por favor, insira um email válido.");
      return false;
    }
    if (!password) {
      setErrorMessage("Por favor, insira sua senha.");
      return false;
    }
    return true;
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validateInputs()) return;

    setLoading(true);
    setErrorMessage("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        const { token, name, role, empresa_id, empresa_name, plano } = data;
        if (token && name && role && empresa_id && empresa_name && plano) {
          console.log(
            "Login bem-sucedido, salvando dados no localStorage:",
            data
          );
          localStorage.setItem("token", token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              name,
              role,
              empresa_id,
              empresa_name,
              plano,
            })
          );
          setAuth(true); // Atualiza o estado de autenticação
          navigate("/analiseesg", { replace: true });
        } else {
          setErrorMessage("Erro no login: dados incompletos recebidos.");
        }
      } else {
        setErrorMessage(data.msg || "Erro no login.");
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setErrorMessage("Erro ao fazer login: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh", backgroundColor: "#f0f0f0" }}>
      <Box
        sx={{
          flex: 1,
          backgroundImage: "url(/background.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundColor: "#f0f0f0",
        }}
      />
      <Box
        component={Paper}
        elevation={6}
        square
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Typography component="h1" variant="h5">
          Tela de Login
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleLogin}
          sx={{ mt: 1, width: "100%", maxWidth: "400px" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? "Carregando..." : "Entrar"}
          </Button>
        </Box>
      </Box>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default LoginForm;

