// esg-frontend/src/components/SideMenu.js

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import {
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
  Drawer,
  CssBaseline,
  ListItemButton,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";

const SideMenu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    indicadoresEMetas: false,
    analiseEsg: false,
    userManagement: false,
    settings: false,
  });

  const userContext = useContext(UserContext);

  if (!userContext) {
    console.log("Contexto de usuário ainda não está disponível.");
    return <div>Loading...</div>;
  }

  const { plano } = userContext; // Agora seguro para desestruturar, dado que sabemos que não é nulo

  const handleMenuClick = (menu) => {
    setOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const submenus = {
    diagnósticoEsg: [
      { name: "Meio Ambiente", path: "/meioambiente" },
      { name: "Social", path: "/social" },
      { name: "Governança", path: "/governanca" },
      { name: "Resultado ESG", path: "/analisesa" },
      { name: "Análise ESG", path: "/analiseesg" },
      { name: "ESG Road Map", path: "/roadmap" },
      { name: "Relatório de Ações", path: "/relatorioacoes" },
    ],
    indicadoresEMetas: [
      { name: "Dashboard", path: "/dashboard" },
      { name: "ABNT PR 2030", path: "/abnt" },
      { name: "Indicadores", path: "/indicadores" },
      { name: "Metas", path: "/metas" },
      { name: "Resultados", path: "/resultados" },
      { name: "Planejamentos", path: "/planejamentos" },
      { name: "Relatórios Geral", path: "/relatoriogeral" },
      { name: "Relatórios ESG", path: "/relatorioesg" },
      { name: "Relatórios Planejamento", path: "/relatorioplanejamento" },
    ],
    analiseEsg: [],
    menuSuperadmin: [{ name: "Empresas e Usuários", path: "/permicoesuser" }],
    // configuracoes: [
    //   { name: "Configurações da Empresa", path: "/configuracoesempresa" },
    // ],
  };

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="persistent"
        open={true}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
            overflowY: "auto",
            height: "100vh",
            position: "fixed",
            top: 0,
            backgroundColor: "darkblue",
          },
        }}
      >
        <List sx={{ paddingTop: "60px", color: "white" }}>
          {/* Condicionalmente renderizando menus com base no plano */}
          {(plano === "full" || plano === "basic") && (
            <>
              {/* Diagnóstico ESG */}
              <React.Fragment>
                <ListItemButton
                  onClick={() => handleMenuClick("diagnósticoEsg")}
                >
                  <ListItemIcon sx={{ color: "white" }}>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Diagnóstico ESG" />
                  {open.diagnósticoEsg ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.diagnósticoEsg} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {submenus.diagnósticoEsg.map((submenu) => (
                      <ListItemButton
                        key={submenu.name}
                        sx={{ pl: 4 }}
                        onClick={() => navigate(submenu.path)}
                      >
                        <ListItemText primary={submenu.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>

              {/* Indicadores e Metas */}
              {plano === "full" && (
                <React.Fragment>
                  <ListItemButton
                    onClick={() => handleMenuClick("indicadoresEMetas")}
                  >
                    <ListItemIcon sx={{ color: "white" }}>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Indicadores e Metas" />
                    {open.indicadoresEMetas ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={open.indicadoresEMetas}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {submenus.indicadoresEMetas.map((submenu) => (
                        <ListItemButton
                          key={submenu.name}
                          sx={{ pl: 4 }}
                          onClick={() => navigate(submenu.path)}
                        >
                          <ListItemText primary={submenu.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              )}
            </>
          )}

          {/* Menu Superadmin - Apenas para usuários com perfil superadmin */}
          {userContext.role === "superadmin" && (
            <React.Fragment>
              <ListItemButton onClick={() => handleMenuClick("menuSuperadmin")}>
                <ListItemIcon sx={{ color: "white" }}>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Menu Superadmin" />
                {open.menuSuperadmin ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open.menuSuperadmin} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {submenus.menuSuperadmin.map((submenu) => (
                    <ListItemButton
                      key={submenu.name}
                      sx={{ pl: 4 }}
                      onClick={() => navigate(submenu.path)}
                    >
                      <ListItemText primary={submenu.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default SideMenu;

